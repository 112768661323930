@import 'styles/colors/var.css';

.sepa_direct_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.iban_valid_text {
  color: #00855d;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--font-family);
  margin-top: -8px;
}

.iban_invalid_text {
  color: #c1261e;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--font-family);
  margin-top: -8px;
}

.subsection_title {
  font-size: 20px;
  margin: -12px 0 36px 0;
}

@media (max-width: 560px) {
  .no_margin {
    margin-bottom: 0;
  }

  .radio_wrapper {
    margin-bottom: 24px;
  }
}
