.floating_label_input {
  position: relative;
  margin-bottom: 16px;
}

.custom_select {
  width: 100%;
  border-color: var(--color-neutral-300);
  border-radius: 0;
  height: 56px;
}

.label {
  position: absolute;
  top: 14px;
  left: 12px;
  pointer-events: none;
  transition: 0.2s ease all;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  color: var(--color-neutral-400);
}

.labelFloating {
  top: 2px;
  font-size: 12px;
  color: var(--color-neutral-400);
}