@import 'styles/colors/var.css';

.basket_card_wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary-100);
  padding: 32px;
  padding-top: 0;
  position: sticky;
  top: 15%;
}

.basket_card_wrapper.margin_top {
  margin-top: 25px;
}

.price_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.basket_title {
  font-family: var(--font-family);
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-neutral-600);
}

.package_speed {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  color: var(--color-neutral-600);
}

.monthly_label,
.one_time_payment_label {
  color: var(--color-neutral-500);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
}

.monthly_price,
.monthly_price_sm,
.one_time_price {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
}

.monthly_price_sm {
  font-size: 16px;
  font-weight: 600;
}

.monthly_label_wrapper {
  display: flex;
  flex-direction: column;
}

.monthly_label_duration {
  color: var(--color-neutral-500);
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
}

.tax_disclaimer {
  font-family: var(--font-family);
  color: var(--color-neutral-500);
  margin-top: 12px;
}

@media only screen and (min-width: 1500px) {
  .basket_card_wrapper {
    margin-left: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .basket_card_wrapper {
    position: relative;
  }

  .basket_card_wrapper_full_screen {
    display: flex;
    flex-direction: column;
    background-color: var(--color-primary-100);
    padding: 32px;
    height: 100vh;
  }
}

@media only screen and (max-width: 1200px) {
  .price_wrapper {
    flex-wrap: wrap;
  }

  .basket_title {
    font-size: 1.5rem;
  }
}
