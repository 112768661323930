.error_message_wrapper {
  margin-top: 16px;
}

.generate_pdf_text {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
}
