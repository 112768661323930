@import 'styles/colors/var.css';

.connection_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
}

.section_sub_title {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  margin: 32px 0;
}

.check_options_wrapper {
  margin-bottom: 32px;
}

.transfer_warning_wrapper {
  display: flex;
  flex: 1;
  padding: 32px;
  background-color: var(--color-neutral-100);
  margin-top: 32px;
}

.transfer_warning_text {
  color: var(--color-neutral-500);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  margin-left: 12px;
}

.select_provider {
  width: 100%;
  height: 56px;
}

.section_input {
  border-radius: 0px;
  height: 56px !important;
}

.section_input_sm {
  display: none;
  border-radius: 0px;
  font-size: 12px;
  height: 56px !important;
  color: var(--color-neutral-600) !important;
}

.error_message_wrapper {
  margin-top: 24px;
  margin-bottom: 0;
}

.connection_owner_company_wrapper {
  margin-top: 24px;
}

.connection_owner_info_wrapper {
  margin: 24px 0;
}

.connection_owner_info_wrapper_mb {
  margin-bottom: 24px;
}

.add_phone_number_button {
  padding: 4px;
}

.add_phone_number_button:hover {
  background: var(--color-neutral-100) !important;
}

.add_phone_number_text {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
}

.delete_icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete_icon svg {
  height: 24px;
  width: 24px;
}

@media (min-width: 560px) {
  .area_code_sm {
    display: none;
  }

  .country_code_sm {
    display: none;
  }
}

@media (max-width: 560px) {
  .add_phone_number_button {
    height: fit-content;
    white-space: unset;
  }

  .transfer_warning_wrapper {
    margin-top: 12px;
  }

  .connection_owner_info_wrapper {
    margin: 38 0 0 0;
    margin-top: 38px;
    margin-bottom: 0;
  }

  .radio_wrapper {
    margin-bottom: 24px;
  }

  .country_code {
    display: none;
  }

  .area_code {
    display: none;
  }

  .section_input {
    display: none;
  }

  .section_input_sm {
    display: block;
    text-align: center;
  }
}
