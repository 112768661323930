@import 'styles/colors/var.css';

.no_package_container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.content_wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.no_package_text {
  font-size: 48px;
  font-family: var(--font-family);
  font-weight: 700;
  color: var(--color-neutral-600);
  text-align: center;
}

.select_on_website_text {
  font-size: 20px;
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--color-neutral-500);
  text-align: center;
  margin: 24px 0;
}

.go_to_website_button {
  border-radius: 0px !important;
  background: var(--color-neutral-600) !important;
  color: var(--color-neutral-50);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
  height: 56px;
}

.go_to_website_button:hover {
  outline: none;
  border-color: var(--color-neutral-600) !important;
  color: var(--color-neutral-50) !important;
  background: var(--color-neutral-600);
}

.go_to_website_button a:hover {
  color: var(--color-neutral-50);
}

@media screen and (max-width: 980px) {
  .content_wrapper {
    width: 100%;
    padding: 24px;
    margin-top: 24px;
  }

  .no_package_text {
    font-size: 24px;
  }
}
