@import 'styles/colors/var.css';

.term_id {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
}

.term_text {
  font-family: var(--font-family);
  font-size: 16px;
  padding-left: 6px;
}

.order_summary_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
}

.user_additional_info_text {
  color: var(--color-neutral-500);
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
}

.user_info_text {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  margin-top: 16px;
}

.checkbox_text {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
}

.user_info_wrapper {
  display: flex;
}

.user_title,
.user_group {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
}

.user_group {
  display: flex;
  align-items: center;
  gap: 12px;
}

.user_title {
  min-width: 300px;
}

.user_group_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.user_group_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.user_group_icon {
  width: 30px;
  height: 30px;
}

.mobile_divider {
  display: none;
}

.transfer_info_title {
  font-size: 24px;
  font-family: var(--font-family);
  color: var(--color-neutral-600);
}

.disclaimer {
  font-size: 16px;
  font-family: var(--font-family);
  color: var(--color-neutral-500);
}

@media screen and (max-width: 992px) {
  .user_info_wrapper {
    margin-bottom: 16px;
  }

  .user_group_wrapper {
    justify-content: flex-start;
  }

  .user_group_icon_wrapper {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .mobile_divider {
    display: block;
  }

  .order_summary_container {
    padding: 12px;
  }

  .summary_user_name {
    text-align: left;
  }

  .user_title {
    min-width: 216px;
  }
}
