@import 'styles/colors/var.css';

.drag-and-drop-input .ant-upload-drag {
  background: var(--color-neutral-50);
  border: 1px dashed var(--color-neutral-400);
  border-radius: 0;
  padding: 32px;
}

.drag-and-drop-input-hidden .ant-upload-drag {
  display: none;
}

.drag-and-drop-input-upload-button {
  background-color: transparent;
  border-color: var(--color-neutral-600);
  color: var(--color-neutral-600);
  border-radius: 0 !important;
  width: fit-content;
  min-width: 140px;
  height: 48px;
  margin-top: 24px;
}

.drag-and-drop-input-upload-button-text {
  margin-left: 8px;
}

.drag-and-drop-input-upload-button-icon {
  display: flex !important;
  justify-content: center;
}

.drag-and-drop-input-upload-button:hover {
  background-color: transparent !important;
  border-color: var(--color-neutral-600) !important;
  color: var(--color-neutral-600) !important;
  box-shadow: 3px 4px 12px -1px rgba(0, 0, 0, 0.4);
  transform: translateY(-0.25em);
}

.drag-and-drop-input-main-text {
  font-family: var(--font-family);
  color: var(--color-neutral-500);
  font-weight: 600;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .drag-and-drop-input-upload-button {
    width: 100%;
  }
}
