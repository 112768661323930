@import 'styles/colors/var.css';

.upload_message_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  align-items: flex-start;
  gap: 12px;
  margin-top: 24px;
}

.upload_message_error {
  background-color: var(--color-error-100);
  color: var(--color-error-500);
  border: 1px solid var(--color-error-200);
}

.upload_message_success {
  background-color: var(--color-success-100);
  color: var(--color-success-500);
  border: 1px solid var(--color-success-200);
}

.upload_message_warning {
  background-color: var(--color-warning-100);
  color: var(--color-warning-600);
  border: 1px solid var(--color-warning-200);
}

.full_width_row {
  width: 100%;
}

.upload_message {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
}
