@import 'styles/colors/var.css';

.selected_item_wrapper_uploaded {
  margin-top: 20px;
  justify-content: center;
  background: var(--color-neutral-50);
  border: 1px solid var(--color-neutral-200);
  padding: 16px;
}

.selected_item_wrapper {
  margin-top: 20px;
  justify-content: center;
  background: transparent;
  border: 1px dashed var(--color-neutral-300);
  padding: 16px;
}

.selected_item_icon {
  justify-content: center;
}

.selected_item_filename {
  display: flex;
  justify-content: left;
  font-family: var(--font-family);
  color: var(--color-neutral-600);
  font-weight: 700;
  font-size: 16px;
}

.icon_wrapper {
  cursor: pointer;
  display: flex;
}
