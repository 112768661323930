@import 'styles/colors/var.css';

.info_title {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
}

.info_text {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  white-space: pre-line;
}

.transfer_text {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
}
