.floating-label-input {
  position: relative;
  margin-bottom: 16px;
}

.floating-label-input input {
  width: 100%;
  padding: 8px;
  border-color: var(--color-neutral-300);
  border-radius: 0px;
  height: 56px;
}

.floating-label-input input:hover {
  border-color: var(--color-neutral-400);
}

.floating-label-input input:focus {
  border-color: var(--color-neutral-400);
  outline: none;
  box-shadow: none;
}

.floating-label-input label {
  position: absolute;
  top: 14px;
  left: 12px;
  pointer-events: none;
  transition: 0.2s ease all;
  color: var(--color-neutral-400);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
}

.floating-label-input input:focus + label,
.floating-label-input input:not(:placeholder-shown) + label {
  top: 2px;
  font-size: 12px;
  color: var(--color-neutral-400);
}

.error-message {
  color: #c1261e;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--font-family);
  margin-top: 8px;
}
