@import 'styles/colors/var.css';

h3 {
  margin-bottom: 32px;
}

.section_title {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
}

.section_wrapper {
  margin: 12px 0;
}

.additional_info_text {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
}

.radio_options_wrapper {
  display: flex;
  flex: 1;
  margin-bottom: 24px;
}

.number_section_wrapper {
  margin: 12px 0;
}

.tooltip_icon {
  cursor: pointer;
  margin: 4px 0 0 8px;
}
