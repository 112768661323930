@import 'styles/colors/var.css';

.personal_detail_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.error-message {
  color: #c1261e;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--font-family);
  margin-top: 15px;
}

@media (max-width: 560px) {
  .no_margin {
    margin-bottom: 0;
  }
}
