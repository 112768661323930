@import 'styles/colors/var.css';

.confirm_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 80%;
  padding: 32px;
  height: 70vh;
}

.confirm_title {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 64px;
  font-weight: 700;
  text-align: center;
}

.check_email_text {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
}

.buttons_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 32px;
}

.back_to_site_button {
  border-radius: 0;
  border: 1px solid var(--color-neutral-600);
  color: var(--color-neutral-600);
  height: 56px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
}

.back_to_site_button:hover {
  border-color: var(--color-neutral-600) !important;
  color: var(--color-neutral-600) !important;
  transform: translateY(-0.25em);
}

.download_pdf_button {
  border-radius: 0px;
  background: var(--color-neutral-600);
  color: var(--color-neutral-50);
  height: 56px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
}

.download_pdf_button:hover {
  border-color: var(--color-neutral-600) !important;
  color: var(--color-neutral-50) !important;
  transform: translateY(-0.25em);
  background: var(--color-neutral-600) !important;
}

@media screen and (max-width: 980px) {
  .confirm_container {
    width: unset;
    height: unset;
    flex: 1;
  }

  .confirm_title {
    font-size: 32px;
  }

  .check_email_text {
    text-align: center;
  }
}
