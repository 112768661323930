.additional_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.phone_numbers_select {
  width: 100%;
  border-radius: 0px;
  height: 48px;
}

.number_section_wrapper {
  margin: 8px 0;
}

.radio_group_wrapper {
  margin-bottom: 24px;
}
