.secondary_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-neutral-600);
  border-radius: 0;
  padding: 7px 12px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.secondary_button:hover {
  border-color: var(--color-neutral-600) !important;
  color: var(--color-neutral-600) !important;
  box-shadow: 3px 4px 12px -1px rgba(0, 0, 0, 0.4);
  transform: translateY(-0.25em);
}
