@import 'styles/colors/var.css';

.location_step_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
}

.location_title {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.location_wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--color-neutral-100);
  padding: 48px;
  margin-bottom: 64px;
}

.location_item_wrapper {
  display: flex;
  align-items: center;
}

.location_icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.location_icon svg {
  width: 28px;
  height: 28px;
}

.location_icon svg path {
  fill: #000;
}

.address {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.package_wrapper {
  background-color: var(--color-neutral-100);
  padding: 48px;
}

.package_name {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
}

.description_text {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
}

.offer_price {
  color: var(--color-neutral-600);
  text-align: right;
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
}

.package_standard_price {
  color: var(--color-neutral-500);
  text-align: right;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
}

.section_description {
  font-family: var(--font-family);
  font-size: 16px;
  margin-bottom: 38px;
}

.donation_wrapper {
  background-color: var(--color-neutral-100);
  padding: 48px 48px 32px 48px;
}

.donation_description {
  color: var(--color-neutral-500);
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-right: 16px;
}

.donation_select {
  width: 100%;
  max-width: 324px;
  height: 56px;
}

.card_footer {
  width: 100%;
}

.price_wrapper {
  width: 100%;
}

.price_description {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
}

.section_icon {
  margin-right: 12px;
}

.donation_section_wrapper {
  margin-top: 92px;
}

.donation_input_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.partner_id_input {
  width: 50%;
  padding-right: 8px;
}

.sales_channel_input {
  width: 50%;
  padding-left: 8px;
}

@media only screen and (max-width: 680px) {
  .address {
    font-size: 16px;
    margin-left: 24px;
  }

  .partner_id_input,
  .sales_channel_input {
    padding: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .location_step_container {
    padding: 0 24px;
    margin-top: 32px;
  }

  .location_wrapper {
    padding: 24px;
    margin-bottom: 40px;
  }

  .address {
    font-size: 16px;
  }

  .package_wrapper {
    padding: 32px;
    margin-bottom: 40px;
  }

  .donation_wrapper {
    padding: 24px;
  }

  .donation_select {
    margin-top: 24px;
    max-width: unset;
  }

  .donation_section_wrapper {
    margin-top: 24px;
  }

  .charity_select_dropdown {
    width: auto;
  }
}
