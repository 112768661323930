@import 'styles/colors/var.css';

.collapse_header_wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: var(--font-family);
}

.products_container,
.additional_products_container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.additional_options_title_wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
}

.generic_icon {
  width: 32px;
  height: 32px;
}

.hide_section {
  display: none;
}

.text_black {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
}
