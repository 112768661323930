.primary_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-neutral-600);
  border-radius: 0;
  padding: 7px 12px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  background-color: var(--color-neutral-600);
  color: var(--color-neutral-50);
}

.primary_button:hover {
  color: var(--color-neutral-50) !important;
  border-color: var(--color-neutral-600) !important;
  transform: translateY(-0.25em);
  background-color: var(--color-neutral-600) !important;
}
