:root {
  --color-neutral-50: #ffffff;
  --color-neutral-100: #f6f8f9;
  --color-neutral-200: #e2e7eb;
  --color-neutral-300: #8c959c;
  --color-neutral-400: #646d75;
  --color-neutral-500: #353c43;
  --color-neutral-600: #181716;

  --color-primary-100: #fffee8;
  --color-primary-200: #fff899;
  --color-primary-300: #ffed00;
  --color-primary-400: #ffd200;
  --color-primary-500: #fab915;
  --color-primary-600: #d49c0f;

  --color-secondary-100: #f5f5e4;
  --color-secondary-200: #e7e7c8;
  --color-secondary-300: #d0cfa6;
  --color-secondary-400: #a09e7f;
  --color-secondary-500: #4e4c3f;
  --color-secondary-600: #25241e;

  --color-error-100: #ffefef;
  --color-error-200: #fcc6c6;
  --color-error-500: #a41912;

  --color-success-100: #e6f8f2;
  --color-success-200: #9ce2cd;
  --color-success-500: #044e37;

  --color-warning-100: #fffadd;
  --color-warning-200: #f5cc80;
  --color-warning-600: #503301;

  --text-color: #4e4e4e;
  --font-family: 'Source Sans 3', sans-serif;

  --padding-small: 8px;
  --padding-medium: 16px;
  --padding-large: 24px;
}
