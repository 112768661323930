@import 'styles/colors/var.css';

.steps_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 42px 0 0 0;
}

.steps_wrapper {
  position: sticky;
  top: 0;
  background-color: var(--color-neutral-50);
  z-index: 2;
  padding: 12px 0;
}

.content_container {
  display: flex;
  flex-direction: column;
  width: 80%;
  min-height: 100vh;
}

.navigation_buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  position: sticky;
  bottom: 0px;
  z-index: 2;
  background-color: var(--color-neutral-50);
  padding: 12px 0;
  border-top: 1px solid var(--color-neutral-200);
  margin-top: auto;
}

.prev_button,
.next_button {
  border-color: var(--color-neutral-600);
  color: var(--color-neutral-600);
  border-radius: 0 !important;
  width: fit-content;
  min-width: 140px;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
}

.next_button {
  background-color: var(--color-neutral-600);
  color: var(--color-neutral-50);
}

.prev_button:hover {
  border-color: var(--color-neutral-600) !important;
  color: var(--color-neutral-600) !important;
  box-shadow: 3px 4px 12px -1px rgba(0, 0, 0, 0.4);
  transform: translateY(-0.25em);
}

.next_button:hover {
  color: var(--color-neutral-50) !important;
  border-color: var(--color-neutral-600) !important;
  transform: translateY(-0.25em);
  background-color: var(--color-neutral-600) !important;
}

.accordion_divider {
  margin: 24px 0 0 0px;
}

@media only screen and (max-width: 768px) {
  .steps_container {
    padding: 24px 0;
  }

  .content_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .steps_wrapper {
    position: inherit;
    padding: 0 12px;
  }

  .navigation_buttons {
    justify-content: center;
    position: unset;
    padding: 12px;
  }

  .next_button,
  .prev_button {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .step {
    width: 48px;
    text-align: center;
  }

  .step > div > div {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 769px) {
  .content_container {
    width: 90%;
  }
}
