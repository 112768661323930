@import 'styles/colors/var.css';

.payment_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.authorization_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  margin: 24px 0;
}

.order_form_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.required_text {
  padding-left: 8px;
}

.explanation_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 24px 0;
}

.explanation_list_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 0 0 18px;
  margin-top: 24px;
}

.explanation_text {
  color: var(--text-color);
}

.accordion_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.accordion_heading span {
  margin-right: 16px;
}

.bracket_text {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
}

.error_style_title {
  color: var(--color-error-500);
}

@media only screen and (max-width: 650px) {
  .accordion_heading {
    flex-direction: column;
  }
}
