.contact_person_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (max-width: 560px) {
  .no_margin {
    margin-bottom: 0;
  }
}
