@import 'styles/colors/var.css';

.info_title {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
}

.info_text {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  white-space: pre-line;
}

.payment_option_text {
  text-transform: capitalize;
}

.mobile_margin_l {
  margin-bottom: 32px;
}

@media (max-width: 576px) {
  .info_text {
    margin-bottom: 24px;
  }

  .mobile_margin_l {
    margin-bottom: 0px;
  }
}
