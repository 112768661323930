@import 'styles/colors/var.css';

.sidebar_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: var(--color-neutral-600);
  width: 300px;
  height: 100vh;
  position: sticky;
  top: 0;
}

.logo_icon_wrapper {
  position: sticky;
  top: 4rem;
}

.sidebar_contact_container {
  display: flex;
  flex-direction: column;
  color: var(--color-neutral-300);
  line-height: 1.5;
  gap: 2px;
}

.sidebar_text_container {
  padding: 0 0 4rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.sidebar_text_container a {
  font-weight: 500;
  color: var(--color-neutral-200);
  text-decoration: none;
}

.bottom_text {
  color: var(--color-neutral-300);
  text-align: left;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 980px) {
  .sidebar_container {
    flex-direction: row;
    width: unset;
    max-height: 20vh;
    min-height: 12vh;
    justify-content: flex-start;
    padding: 0 24px;
    flex: 1;
    position: inherit;
  }

  .logo_icon_wrapper {
    width: 100%;
    justify-content: space-between;
    padding: 0 12px;
    top: 0px;
  }

  .cart_wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .cart_icon {
    width: 32px;
    height: 32px;
  }

  :global(.ant-badge .ant-badge-count) {
    color: var(--color-neutral-600);
    box-shadow: none;
    font-weight: 700;
    font-size: 14px;
    font-family: var(--font-family);
  }

  .sidebar_text_container {
    display: none;
  }
}
