@import 'styles/colors/var.css';

.error_message_container {
  display: flex;
  flex-direction: row;

  background-color: #ffefef;
  padding: 32px 32px 32px 32px;
  align-items: flex-start;

  color: #a41912;
  margin-bottom: 24px;
}

.error_message {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
}

.img {
  position: relative;
  padding-right: 8px;
}
